import React from 'react';
import styled from '@emotion/styled';
import * as constants from '../constants';

export const TakeActionLink = styled.a`
  cursor: pointer;
  color: ${constants.highlightForegroundColor};
  background-color: ${constants.highlightColor};
  font-weight: bold;
  border-radius: 8px;
  border: none;
  padding: 1.2em;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: ${constants.colorTransition};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767.98px) {
    width: 100%;
    font-size: 15px;
  }

  .icon {
    margin-left: 1rem;

    path {
      transition: fill linear 150ms;
    }
  }

  &:hover {
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &.dark {
    background-color: ${constants.highlightForegroundColor};
    color: ${constants.highlightColor};

    &:hover {
      color: #fff;

      .icon {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const TakeActionButton = TakeActionLink.withComponent('button');
